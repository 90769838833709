import React, { useState, useEffect, useRef, useContext } from "react"
import { GlobalAuthContext } from "../../../Contexts/GlobalAuthContext"
import styled from "styled-components"
// import { Document, Page } from "react-pdf"
// import pdfjs from "pdfjs-dist"

import displayPdfSyncPreview from "../helpers/displayPdf-Preview"
import { FileUploader } from "react-drag-drop-files"
import TryPdf from "../../shared/TryPdf"
import ArrowRight from "../../../icons/tools/arrow-right.svg"
import RestartIcon from "../../../icons/tools/Restart.svg"

import PPTIcon from "../../../icons/tools/toPPT/toPPT.svg"
import CompleteIcon from "../../../icons/tools/mergedIcon.svg"

import ErrorMessage from "../ErrorMessage"
import UploadIcon from "../../../icons/tools/upload.svg"
import ArrowTry from "../../../icons/tools/tryPdfArrow.svg"
import { ToExcel } from "./Actions"
import Warning from "../Warning"
import PrivacyQuestion from "../../../icons/tools/PrivacyQuestion.svg"
import GDPRwarning from "../GlobalToolComponents/GDPRwarning"
import {
  DownloadScreen,
  CanvasStyle,
  LoadingScreen,
  Pdfpreview,
} from "../Styles"
import Disclaimer from "../GlobalToolComponents/Disclaimer"
import Title from "../GlobalToolComponents/ToolH2tag"
import { formatBytes } from "../helpers/helpers"

const LoadingCircle = styled.div`
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const FileUploaderStyle = styled.div`
  margin: auto;
  max-width: 532px;
  width: 100%;
  margin-top: 60px;

  .drop_zone {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed red;
    width: 100%;
    min-height: 374px;
    background: #f5f6fc;
    border: 1px dashed #6361ff;
    border-radius: 2px;
    margin: 8px 0;
  }

  :hover {
    max-width: 548px;
    .drop_zone {
      min-height: 390px;
      background: #ebeeff;
      margin: 0;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 18px;
      line-height: 28px;
      color: #425466;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 8px;
      :after {
        content: "Drag and drop your PDF file here";
      }
      @media (max-width: 744px) {
        :after {
          content: "Tap to upload PDF file here";
        }
      }
    }
    .info {
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      color: #6361ff;
      text-transform: capitalize;

      :after {
        content: "PDF files smaller than 20MB, max 100 pages";
      }
      @media (max-width: 744px) {
        :after {
          content: "PDF files smaller than 20MB";
        }
      }
    }

    button {
      all: unset;
      margin-top: 40px;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;
      padding: 12px 24px;
      gap: 12px;
      background: #6361ff;
      border-radius: 8px;
      cursor: pointer;
      @media (max-width: 744px) {
        display: none;
      }
    }
  }
`
const Offline = styled.div`
  background: #e3e8ee;
  border-radius: 2px;
  width: 73px;
  height: 26px;
  font-size: 13px;
  line-height: 22px;
  text-transform: uppercase;
  color: #6a7485;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  :hover {
    .warning {
      opacity: 1;
    }
  }
`

const ToPdf = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  a {
    color: #6361ff;
    text-decoration: none;
  }

  .desktop {
    display: inline-block;
    @media (max-width: 600px) {
      display: none;
    }
  }
`
const CtaPopup = styled.div`
  color: #6361ff;
  text-decoration: none;
  display: inline;
  cursor: pointer;
  white-space: nowrap;
`
const SingleFileUploadStyle = styled.div`
  min-width: 205px;
  min-height: 283px;
  width: max-content;
  margin: 0;
  margin-bottom: 90px;
  display: inline-block;

  .drop_zone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 283px;
    background: #f5f6fc;
    border: 1px dashed #6361ff;
    border-radius: 2px;
    cursor: pointer;

    :hover {
      background: #ebeeff;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .title {
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #6361ff;
      font-weight: 800;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .info {
      max-width: 97px;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      color: #6361ff;
      white-space: pre-wrap;
    }
  }
`

const Pdfholder = styled.div`
  min-height: 283px;
  min-height: 400px;
  /* background: rgba(216, 219, 253, 0.5); */

  display: flex;
  align-items: center;
  gap: 32px;
  padding: 0 8px;
`

const ExportButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;
  width: max-content;
  white-space: nowrap;
  transition: background-color 1s;
  height: 48px;
  background: #6361ff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;

  svg {
    min-width: 24px;
    min-width: 24px;
  }

  @media (max-width: 440px) {
    width: 90%;
  }
`

const SecondScreen = styled.div`
  /* ${props => props.hide && "display: none;"} */
  /* transform: translateY(124px); */
`

const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 40px;

  overflow: visible;

  @media (max-width: 440px) {
    padding-top: 32px;
    flex-direction: row;
    margin: auto;
    align-items: center;
  }
`

const Message = styled.div`
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #425466;
  margin-bottom: 8px;
  @media (max-width: 440px) {
    margin-bottom: 0;
    display: none;
  }
`
const Loading = styled.div`
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  color: #333333;
  margin-bottom: 24px;
`

const BarBack = styled.div`
  width: 320px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
`

const BarButton = styled.div`
  ${props => `width: ${320 * props.percent}px;`}
  background: #6361FF;
  height: 8px;

  border-radius: 4px;
`
const Percent = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #8d9092;
  margin-top: 8px;
`

const DownloadTitle = styled.div`
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin: 24px 0;
  @media (max-width: 744px) {
    font-size: 32px;
    line-height: 32px;
  }
`

const DownloadMessage = styled.div`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #425466;
  @media (max-width: 744px) {
    font-size: 16px;
    line-height: 26px;
  }
`
const DownloadButton = styled.div`
  min-width: 156px;
  height: 48px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: #6361ff;
  border-radius: 8px;
  margin-top: 40px;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 440px) {
    width: 100%;
  }
`

const ErrorMessageHolder = styled.div`
  background: red;
  height: 100%;
  width: 0px;
  position: absolute;
  left: 50%;
  bottom: 0;
  padding-bottom: 258px;
  padding-top: 258px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 744px) {
    justify-content: flex-start;
  }
`

const InputHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: 24px;
    height: 24px;
    border: 1px solid #d7d6d6;
    border-radius: 0;
    cursor: pointer;
  }
  label {
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #0b263f;
    margin-left: 12px;
  }

  input[type="checkbox"]:not(:checked) {
    -webkit-appearance: none;
    appearance: none;
  }

  input[type="checkbox"]:checked {
    border-radius: 0;
    background: red;
  }
`

const Restart = styled(ToPdf)`
  margin-top: auto;
  display: flex;
  margin-bottom: 32px;
`

const RestartSecond = styled(Restart)`
  justify-content: center;
  margin-top: 32px;
  position: absolute;
  bottom: 0;
  width: 90vw;
  max-width: 1080px;
`

const CtaRestat = styled(CtaPopup)`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 8px;
  }
`

const Info = styled.div`
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #425466;
  max-height: 0;
  overflow: visible;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: 440px) {
    display: none;
  }
`

function PPTTool() {
  const [file, setFile] = useState(null)
  const [errorFiles, setErrorFiles] = useState([])
  const [repository, setRepository] = useState([])
  const [fileRendered, setFileRendered] = useState(0)
  const [pageNum, setPageNum] = useState(null)
  const [download, setDownload] = useState(null)
  const [errors, setErrors] = useState([])
  const [password, setPassword] = useState("")
  const [fileSize, setFileSize] = useState("")

  const [loading, setLoading] = useState(false)

  const pdfHolder = useRef(null)
  const pdfPreview = useRef(null)

  useEffect(() => {
    if (pdfHolder?.current) {
      function detectScrollability() {
        if (pdfPreview.current?.scrollWidth) {
          if (pdfPreview.current.scrollWidth > pdfPreview.current.clientWidth) {
            pdfPreview.current.style.justifyContent = "normal"
          } else {
            pdfPreview.current.style.justifyContent = "center"
          }
        }
      }

      window.addEventListener("resize", () => {
        detectScrollability()
      })
      const observer = new MutationObserver(detectScrollability)

      detectScrollability()
      observer.observe(pdfPreview.current, {
        attributes: true,
        childList: true,
        subtree: true,
      })
    }
  }, [pdfHolder.current, pdfPreview.current?.scrollWidth])

  const { setScroll } = useContext(GlobalAuthContext)

  const [myPopUp, setMyPopUp] = useState("hide")
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    setMyPopUp("show")
    setScroll("show")
  }

  function CutEveryPage(e) {
    const cuts = document.getElementsByClassName("cut")
    if (e.target.checked) {
      Array.prototype.forEach.call(cuts, childElement => {
        childElement.firstChild.classList.add("cutted")
        childElement.style.background = "#f8f9fa"
      })
    } else {
      Array.prototype.forEach.call(cuts, childElement => {
        childElement.firstChild.classList.remove("cutted")
        childElement.style.background = "none"
      })
    }
  }

  useEffect(() => {
    if (file) {
      const parent = document.getElementById("canvas-storage")

      const observer = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            const addedNodes = mutation.addedNodes
            let i = 1
            for (let node of addedNodes) {
              if (node.nodeType === Node.ELEMENT_NODE) {
                if (node.classList.contains("cut")) {
                  console.log("Cut")
                  node.addEventListener("click", e => {
                    if (node.firstChild.classList.contains("cutted")) {
                      node.firstChild.classList.remove("cutted")
                      node.style.background = "none"
                    } else {
                      node.firstChild.classList.add("cutted")
                      node.style.background = "#f8f9fa"
                    }
                  })
                }
              }
              i++
            }
          }
        }
      })

      observer.observe(parent, { childList: true })
    }
  }, [file])

  useEffect(() => {
    if (fileRendered == 1) {
      setFileRendered(0)
      setLoading(false)
      console.log(errorFiles)
      setFileSize(formatBytes(file.size))
      if (errorFiles.length == 0) {
        setRepository([file])
        setErrorFiles([])
      } else {
        setFile(null)
        setErrorFiles([])
      }
    }
  }, [fileRendered])

  function removePassProtected(fileName) {
    let str = fileName + " is password protected"
    ErrorPopup(str)
  }

  useEffect(() => {
    console.log("File: ", file)
  }, [file])

  async function pdfInput(file) {
    const fileReader = new FileReader()
    fileReader.onload = async function () {
      const pdfData = new Uint8Array(this.result)
      await displayPdfSyncPreview(
        pdfData,
        file,
        "canvas-storage",
        1,
        setFileRendered,
        removePassProtected,
        setErrorFiles,
        ErrorPopup,
        setPageNum
        // setPassword
      )
    }
    fileReader.readAsArrayBuffer(file)
  }

  function restartTool(e) {
    window.location.reload()
  }

  const fileTypes = ["pdf"]

  const handleChange = file => {
    setLoading(true)
    setFile(file)
    pdfInput(file)
  }

  const filesSubmit = () => {
    const formData = new FormData()

    formData.append(repository[0].name, repository[0])

    setDownload(true)
    let data = {
      formData: formData,
      password: window.realPass,
      fileName: repository[0].name,
    }
    ToExcel(data, setDownload)
  }

  function ErrorPopup(message) {
    let oldEr = [...errors, message]
    setErrors(er => [...er, message])
    setTimeout(() => {
      if (
        oldEr.length == errors.length &&
        JSON.stringify(oldEr) == JSON.stringify(errors)
      ) {
        setErrors([])
      }
    }, 5000)
  }
  if (download != null) {
    return (
      <DownloadScreen>
        <CompleteIcon />
        {download === true && (
          <DownloadTitle>Your PDF is now being converted</DownloadTitle>
        )}

        {download !== true && (
          <DownloadTitle>
            Your PDF file has been converted to PowerPoint
          </DownloadTitle>
        )}
        <DownloadMessage>
          Your file will automatically download once it's ready. If it doesn't,
          use the button below.
        </DownloadMessage>
        {download === true && (
          <DownloadButton>
            <LoadingCircle />
            Processing...
          </DownloadButton>
        )}
        {download !== true && (
          <DownloadButton onClick={() => download.click()}>
            Download File
          </DownloadButton>
        )}
        <Restart>
          <div className="desktop">Need to convert another file?</div>
          <CtaRestat onClick={restartTool}>
            <RestartIcon /> Start Over{" "}
          </CtaRestat>
        </Restart>
      </DownloadScreen>
    )
  }

  return (
    <CanvasStyle>
      <ErrorMessageHolder>
        {errors.map(m => {
          return <ErrorMessage message={m}></ErrorMessage>
        })}
      </ErrorMessageHolder>
      {!file && (
        <div className="firstScreen">
          <PPTIcon />
          <h1>PDF to PowerPoint Converter</h1>
          <Title>
            Easily convert PDF to PowerPoint (.pptx), directly in your browser!
            It's fast, free, and works on any device.{" "}
          </Title>
          <FileUploaderStyle>
            <FileUploader
              handleChange={handleChange}
              multiple={false}
              types={fileTypes}
              maxSize={20}
              onSizeError={() => {
                ErrorPopup("Your file needs to be under 20MB size")
              }}
              classes="drop_area drop_zone "
              children={
                <div className="column">
                  <UploadIcon />
                  <div className="title"></div>
                  <div className="info"></div>
                  <button>Upload file</button>
                </div>
              }
            />
          </FileUploaderStyle>
          <Row>
            <Offline>Offline</Offline>
            <ToPdf>
              <div className="desktop">Rather work offline?&nbsp;</div>
              <CtaPopup onClick={openPopup}>
                Try PDF Pro for free today! <ArrowTry />{" "}
              </CtaPopup>
            </ToPdf>
          </Row>

          <Row>
            <Warning />
            <GDPRwarning>
              <Disclaimer />
              <PrivacyQuestion />
            </GDPRwarning>
          </Row>
          {myPopUp === "show" && <TryPdf close={closePopup} />}
        </div>
      )}
      <LoadingScreen hide={loading}>
        <Loading>Loading... </Loading>
        <BarBack>
          <BarButton percent={fileRendered} />{" "}
        </BarBack>
        <Percent>{(fileRendered * 100).toFixed(0)}%</Percent>
      </LoadingScreen>
      {file && (
        <SecondScreen>
          <Message id="message">Press Convert to PowerPoint</Message>

          <Pdfpreview ref={pdfPreview} overflowY={pageNum == 1}>
            <Pdfholder id="canvas-storage" ref={pdfHolder}>
              {" "}
            </Pdfholder>
          </Pdfpreview>

          <ButtonHolder>
            <ExportButton onClick={filesSubmit}>
              Convert to PowerPoint <ArrowRight />
            </ExportButton>
          </ButtonHolder>
          <Info>
            {" "}
            {file.name}, {pageNum} Pages, {fileSize}{" "}
          </Info>
          <RestartSecond>
            <div className="desktop">Need to convert another file?</div>
            <CtaRestat onClick={restartTool}>
              <RestartIcon /> Start Over{" "}
            </CtaRestat>
          </RestartSecond>
        </SecondScreen>
      )}
    </CanvasStyle>
  )
}

export default PPTTool
